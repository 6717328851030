import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M4860 7735 c0 -7 68 -75 75 -75 14 0 2 22 -27 49 -31 29 -48 38 -48
26z"
          />
          <path
            d="M4818 7683 c-22 -5 -24 -43 -3 -43 8 0 15 -4 15 -10 0 -24 16 -7 18
19 2 33 -4 40 -30 34z"
          />
          <path
            d="M4957 7644 c-9 -10 4 -58 18 -66 13 -8 7 -78 -7 -78 -6 0 -8 -15 -6
-34 4 -38 -16 -40 -28 -3 -3 12 -11 28 -17 35 -5 7 -11 29 -13 49 -6 66 -22
64 -79 -11 -21 -28 -25 -44 -25 -104 0 -46 4 -72 11 -72 7 0 10 -15 7 -40 -3
-35 -1 -39 22 -43 20 -3 18 -4 -8 -6 -29 -1 -33 2 -27 19 5 14 2 20 -9 20 -19
0 -21 26 -3 33 7 3 -2 19 -27 45 -29 31 -43 39 -55 32 -19 -10 -71 -2 -71 12
0 5 -4 6 -10 3 -5 -3 -20 -8 -31 -10 -36 -7 -36 -16 -1 -45 23 -19 41 -26 60
-23 21 3 23 2 8 -5 -17 -8 -16 -11 15 -32 19 -13 36 -27 39 -31 12 -17 117
-59 148 -59 64 0 104 68 119 205 15 143 15 152 -1 183 -18 34 -19 36 -29 26z
m-317 -246 c0 -13 -23 -5 -28 10 -2 7 2 10 12 6 9 -3 16 -11 16 -16z"
          />
          <path
            d="M4580 7530 c0 -5 9 -10 20 -10 25 0 25 -9 3 -26 -17 -12 -16 -13 7
-22 22 -8 25 -7 28 15 2 14 1 31 -2 39 -6 16 -56 19 -56 4z"
          />
          <path d="M4470 7494 c0 -17 17 -36 24 -26 3 5 -1 15 -9 22 -8 7 -15 9 -15 4z" />
          <path
            d="M3532 6437 l3 -694 33 -49 c42 -61 99 -104 177 -135 59 -24 65 -24
478 -27 l417 -3 0 800 0 801 -110 0 -110 0 0 -719 c0 -656 -1 -720 -17 -732
-22 -19 -426 -26 -507 -9 -57 12 -106 43 -129 84 -9 16 -13 189 -17 696 l-5
675 -108 3 -107 3 2 -694z"
          />
          <path
            d="M5980 7110 c-16 -16 -20 -33 -20 -90 0 -95 15 -110 108 -110 101 0
112 11 112 110 0 78 -1 80 -30 95 -44 23 -145 20 -170 -5z"
          />
          <path
            d="M4782 6125 l3 -596 108 -2 107 -2 0 512 0 512 22 15 c19 13 58 16
224 16 210 0 250 -6 292 -44 58 -52 57 -42 60 -543 2 -255 6 -463 10 -463 4 0
52 -1 107 -2 l100 -2 0 469 c0 432 -1 474 -18 515 -32 79 -99 136 -212 181
-59 23 -67 24 -433 27 l-372 3 2 -596z"
          />
          <path
            d="M5960 6262 c0 -497 2 -518 54 -587 33 -43 104 -92 170 -118 59 -23
306 -40 318 -22 4 5 6 35 5 65 l-2 55 -97 7 c-116 8 -160 26 -196 81 l-27 41
-3 468 -3 468 -109 0 -110 0 0 -458z"
          />
          <path
            d="M6890 6713 c-71 -11 -173 -57 -220 -100 -92 -83 -94 -90 -98 -455 -2
-211 0 -336 8 -372 15 -72 60 -137 123 -179 100 -67 125 -71 397 -74 135 -2
255 -6 268 -8 22 -5 22 -6 22 -205 l0 -200 110 0 111 0 -3 678 c-3 663 -3 678
-24 722 -41 88 -137 154 -269 186 -56 13 -356 18 -425 7z m382 -145 c54 -16
94 -65 108 -133 8 -37 10 -152 8 -348 -3 -256 -5 -296 -21 -324 -30 -56 -65
-81 -132 -92 -90 -16 -252 -14 -312 4 -56 17 -107 65 -120 113 -14 48 -10 643
5 683 15 42 55 82 97 97 42 15 318 15 367 0z"
          />
          <path
            d="M7752 6238 l3 -483 24 -40 c33 -58 49 -74 113 -115 96 -63 144 -69
541 -72 l347 -3 0 598 0 597 -110 0 -110 0 0 -514 c0 -465 -2 -515 -17 -527
-12 -11 -61 -14 -212 -14 -108 0 -213 4 -233 8 -49 10 -103 62 -117 112 -7 27
-11 191 -11 488 l0 447 -110 0 -111 0 3 -482z"
          />
          <path
            d="M9221 6709 c-61 -12 -163 -61 -205 -100 -20 -17 -47 -55 -61 -82
l-25 -51 0 -353 0 -354 24 -47 c28 -55 86 -111 145 -142 88 -44 135 -49 475
-50 176 0 327 0 334 0 8 0 12 20 12 65 l0 65 -284 0 c-362 0 -403 8 -460 84
-19 25 -21 41 -21 209 l0 182 403 3 404 2 -4 173 c-3 154 -6 176 -26 214 -46
88 -155 160 -277 182 -68 13 -370 13 -434 0z m429 -147 c14 -7 38 -32 55 -55
28 -40 30 -47 30 -137 l0 -95 -290 0 -290 0 -3 80 c-4 120 27 186 103 213 42
16 362 10 395 -6z"
          />
          <path d="M3155 6690 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z" />
          <path
            d="M3200 6661 c0 -12 6 -21 14 -21 8 0 16 -12 18 -27 3 -30 23 -42 31
-19 3 8 -9 30 -29 51 -29 31 -34 34 -34 16z"
          />
          <path
            d="M3273 6575 c-7 -8 -13 -32 -13 -55 0 -33 3 -40 20 -40 16 0 20 7 20
36 0 22 -4 33 -10 29 -7 -4 -9 2 -4 19 7 29 4 31 -13 11z"
          />
          <path
            d="M3144 6473 c-19 -4 -23 -13 -29 -69 -4 -36 -4 -68 -1 -71 3 -4 6 10
6 30 0 26 4 37 15 37 11 0 15 -11 15 -40 0 -22 -4 -40 -9 -40 -5 0 -11 -9 -13
-21 l-3 -20 -22 20 c-14 13 -36 21 -58 21 -32 0 -36 -3 -35 -22 4 -43 0 -58
-15 -58 -23 0 -95 -41 -108 -61 -8 -13 -24 -19 -49 -19 -34 0 -38 -3 -38 -26
0 -50 -20 -57 -138 -51 -59 3 -96 2 -84 -1 l24 -7 -21 -19 c-25 -22 -28 -61
-5 -70 8 -3 14 -14 12 -23 -1 -10 1 -24 5 -30 6 -9 2 -13 -12 -13 -14 0 -21 6
-21 19 0 23 -14 29 -58 25 -24 -2 -36 3 -45 17 -13 20 -27 25 -27 9 0 -18 49
-82 86 -111 25 -19 49 -29 73 -30 29 0 32 -2 16 -9 -37 -16 8 -11 60 7 66 23
223 151 386 317 33 33 59 64 59 68 0 4 5 8 10 8 17 0 130 119 141 147 5 15 7
33 4 41 -5 12 -9 12 -25 -3 -16 -14 -17 -20 -8 -27 8 -5 9 -8 2 -8 -21 0 -34
27 -28 56 5 26 3 30 -18 30 -13 1 -33 -1 -44 -3z m-344 -493 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-30 -4 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-118 -76 c23 -21 17
-25 -27 -20 -27 3 -30 7 -15 25 16 19 17 19 42 -5z"
          />
          <path d="M3281 6444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M2818 6323 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
          <path d="M2331 6254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M2405 6050 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z" />
          <path d="M2152 6041 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z" />
          <path d="M2472 6005 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path
            d="M2220 5960 c0 -5 5 -10 10 -10 6 0 10 -16 10 -35 0 -34 12 -46 25
-24 8 12 -22 79 -35 79 -6 0 -10 -4 -10 -10z"
          />
          <path
            d="M2277 5860 c-4 -14 -11 -18 -24 -14 -13 4 -15 3 -4 -4 19 -14 5 -55
-15 -44 -8 5 -28 6 -45 4 -28 -4 -29 -6 -29 -58 0 -36 -4 -54 -12 -54 -9 0 -8
-4 2 -10 10 -7 -11 -10 -67 -10 l-83 0 0 -35 c0 -34 -1 -35 -40 -35 l-40 0 0
-40 0 -40 -40 0 c-33 0 -40 -3 -40 -19 0 -11 5 -23 10 -26 6 -3 10 -14 9 -23
0 -14 -2 -13 -9 3 -7 17 -9 17 -9 3 -1 -14 -10 -18 -41 -18 -25 0 -40 -5 -40
-12 0 -9 -3 -8 -9 1 -8 12 -12 10 -22 -12 -15 -32 -25 -34 -39 -7 -13 25 -30
26 -31 3 0 -14 -2 -13 -9 5 -12 29 -30 28 -30 -2 0 -27 35 -82 84 -131 29 -29
43 -35 77 -35 22 0 53 6 67 13 15 8 54 27 87 42 33 16 62 32 65 35 3 3 39 22
81 44 87 44 192 123 183 138 -3 5 2 8 12 6 21 -4 65 59 82 117 10 34 8 51 -7
102 -16 53 -56 133 -66 133 -1 0 -5 -9 -8 -20z m30 -95 c6 -14 7 -25 2 -25 -5
0 -9 5 -9 10 0 6 -7 10 -15 10 -16 0 -20 12 -8 23 11 12 18 8 30 -18z m-17
-185 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-383 -120 c1 -11 -4 -20 -13 -20 -17 0 -18 12 -2 28 7 7 12 12 13 12 0 0 1
-9 2 -20z m136 -65 c-10 -7 -22 -13 -27 -13 -5 0 -12 -6 -17 -13 -11 -18 -21
-6 -13 14 6 17 53 38 67 30 5 -3 0 -11 -10 -18z m-353 -35 c16 0 62 -49 56
-59 -8 -13 -76 58 -75 78 1 13 2 13 6 -1 2 -10 8 -18 13 -18z"
          />
          <path d="M1567 5705 c-4 -8 -2 -17 3 -20 6 -4 10 3 10 14 0 25 -6 27 -13 6z" />
          <path d="M1442 5536 c2 -22 8 -41 15 -43 8 -3 9 6 3 32 -12 51 -22 57 -18 11z" />
          <path d="M1592 5540 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path
            d="M1170 5505 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
          />
          <path
            d="M1400 5450 c0 -5 8 -10 18 -10 14 0 15 -2 2 -10 -10 -6 -11 -10 -2
-10 7 0 12 -13 12 -30 0 -20 5 -30 15 -30 8 0 15 -7 15 -15 0 -8 9 -19 20 -25
21 -11 28 -40 10 -40 -5 0 -10 -23 -10 -51 0 -45 -2 -50 -15 -39 -10 8 -15 32
-16 69 -1 31 -3 48 -6 39 -2 -10 -13 -18 -24 -18 -16 0 -19 -7 -19 -40 0 -36
-2 -40 -25 -40 -23 0 -24 -2 -15 -20 6 -11 7 -20 2 -20 -14 0 -36 30 -37 49
-1 9 -2 28 -3 44 -2 20 -7 27 -22 27 -12 0 -20 7 -20 16 0 13 -3 14 -15 4 -8
-6 -19 -9 -25 -5 -6 3 -10 -3 -10 -14 0 -23 15 -28 23 -8 9 20 27 -14 27 -51
0 -42 24 -67 75 -80 42 -11 108 -10 136 2 9 4 20 22 24 39 9 36 6 139 -4 147
-3 3 -6 10 -7 15 -4 65 -34 130 -34 76 0 -10 -7 -21 -15 -25 -12 -4 -15 3 -15
29 0 28 -4 35 -20 35 -11 0 -20 -4 -20 -10z m40 -286 c0 -9 -5 -14 -12 -12
-18 6 -21 28 -4 28 9 0 16 -7 16 -16z"
          />
          <path
            d="M2475 5380 c-50 -8 -79 -23 -199 -105 -53 -36 -156 -98 -229 -137
-73 -39 -144 -84 -159 -99 -64 -69 -45 -158 71 -329 89 -132 171 -190 268
-190 59 0 78 8 263 105 73 39 277 175 322 215 31 27 52 57 62 88 17 48 21 112
8 112 -4 0 -14 15 -20 34 -7 19 -38 66 -70 104 -31 38 -71 87 -89 109 -34 42
-109 88 -158 95 -16 3 -48 2 -70 -2z"
          />
          <path d="M1222 5335 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M1247 5225 c3 -14 10 -25 15 -25 13 0 6 43 -8 47 -7 2 -10 -6 -7 -22z" />
          <path
            d="M6830 5020 c0 -15 7 -20 25 -20 14 0 25 -3 26 -7 0 -5 -40 -169 -88
-365 l-88 -358 -32 0 c-26 0 -33 -4 -33 -20 0 -20 5 -20 162 -18 143 3 163 5
166 20 2 11 -5 18 -20 20 -27 4 -27 16 7 132 l22 79 11 -34 c5 -19 15 -54 20
-79 24 -100 24 -98 0 -102 -13 -2 -24 -11 -26 -20 -3 -17 9 -18 151 -18 132 0
156 2 161 16 9 22 8 22 -16 26 -20 3 -27 19 -60 148 -21 80 -40 155 -43 166
-5 18 6 30 64 73 39 28 78 51 86 51 8 0 15 9 15 20 0 20 -5 21 -82 18 -70 -3
-83 -6 -86 -20 -3 -13 3 -18 22 -18 49 0 41 -9 -112 -114 -46 -31 -87 -54 -91
-52 -5 3 0 36 10 73 22 76 109 413 109 419 0 2 -63 4 -140 4 -133 0 -140 -1
-140 -20z"
          />
          <path
            d="M3320 4960 c0 -15 7 -20 25 -20 23 0 25 -3 19 -27 -3 -16 -25 -104
-49 -198 -23 -93 -52 -210 -64 -260 -37 -158 -45 -180 -72 -184 -23 -3 -39
-17 -39 -34 0 -4 37 -7 82 -5 68 2 83 6 86 20 2 12 -5 17 -25 20 -19 2 -27 8
-24 18 2 8 10 40 17 70 8 30 30 118 50 195 20 77 44 175 54 218 10 43 21 81
24 84 6 6 7 4 26 -67 6 -25 20 -72 31 -105 10 -33 30 -103 45 -155 14 -52 38
-140 53 -195 l27 -100 86 -3 85 -3 6 38 c8 48 18 90 56 238 18 66 36 138 40
160 5 22 22 92 38 155 27 104 32 115 54 118 26 4 49 19 49 33 0 5 -38 9 -84 9
-66 0 -85 -3 -90 -15 -3 -9 0 -17 7 -19 47 -14 47 -4 2 -183 -23 -92 -45 -185
-49 -205 -14 -76 -26 -59 -66 92 -22 85 -44 163 -74 273 l-17 57 -154 0 c-148
0 -155 -1 -155 -20z"
          />
          <path
            d="M1358 4933 c11 -21 23 -45 26 -53 3 -8 19 -37 36 -65 16 -27 39 -68
51 -90 21 -38 58 -89 162 -221 64 -82 113 -107 207 -106 106 0 132 33 93 119
-26 58 -196 314 -233 350 -17 18 -55 43 -83 57 -44 20 -64 23 -132 21 -57 -3
-86 1 -102 11 -38 24 -45 16 -25 -23z"
          />
          <path
            d="M4665 4907 c-49 -12 -95 -25 -101 -29 -6 -4 -17 -34 -24 -67 -12 -57
-14 -60 -44 -63 -40 -4 -51 -38 -12 -38 29 0 31 -9 16 -60 -11 -35 -41 -164
-65 -272 -11 -53 -11 -62 7 -98 15 -30 28 -42 56 -50 48 -13 133 -13 169 1 38
14 101 76 120 119 9 19 18 38 20 43 2 4 -5 7 -15 7 -12 0 -24 -11 -32 -30 -16
-37 -74 -90 -100 -90 -28 0 -26 43 5 166 26 101 41 161 53 224 l7 35 91 3 91
3 19 -238 19 -238 78 0 78 0 77 138 77 139 6 -64 c4 -34 7 -98 8 -140 l1 -78
83 1 82 0 58 117 c145 294 181 356 207 362 14 3 26 14 28 23 3 15 -7 17 -77
17 -74 0 -81 -2 -81 -20 0 -15 7 -20 25 -20 14 0 25 -5 25 -10 0 -6 -30 -70
-67 -143 l-67 -132 -7 50 c-4 28 -8 91 -8 142 -1 88 0 92 21 95 13 2 24 11 26
21 3 16 -9 17 -141 17 -136 0 -145 -1 -150 -20 -4 -15 0 -20 14 -20 16 0 19
-8 19 -53 0 -41 -6 -64 -28 -98 -15 -24 -39 -65 -52 -91 -14 -27 -28 -48 -32
-48 -5 0 -8 65 -8 145 l0 145 29 0 c20 0 30 6 34 20 5 20 0 20 -230 20 l-235
0 6 23 c14 44 35 132 36 145 0 15 -6 15 -115 -11z"
          />
          <path
            d="M4125 4746 c-150 -42 -255 -181 -255 -338 0 -126 76 -188 230 -188
108 0 192 43 244 126 31 49 32 54 6 54 -11 0 -20 -4 -20 -10 0 -5 -14 -25 -32
-45 -59 -68 -147 -94 -197 -59 -22 16 -23 21 -18 93 11 129 -8 116 165 119
l152 3 6 25 c3 13 4 53 2 87 -3 50 -9 69 -28 90 -48 51 -159 70 -255 43z m102
-62 c2 -16 -3 -54 -10 -84 -12 -52 -14 -55 -46 -58 -57 -6 -61 0 -45 55 26 89
54 126 89 119 6 -2 11 -16 12 -32z"
          />
          <path
            d="M5910 4753 c-8 -3 -39 -15 -68 -25 -65 -24 -138 -94 -170 -161 -12
-26 -27 -78 -34 -117 -11 -63 -10 -74 8 -117 13 -32 33 -57 63 -78 41 -29 47
-30 144 -30 92 0 108 3 160 29 102 50 164 138 188 268 13 73 3 134 -30 174
-37 45 -198 80 -261 57z m89 -42 c18 -12 9 -88 -30 -243 -45 -179 -64 -213
-111 -206 -36 5 -34 55 7 224 47 189 63 222 115 233 3 0 11 -3 19 -8z"
          />
          <path
            d="M6597 4740 c-21 -10 -47 -29 -58 -42 l-20 -23 5 38 5 37 -129 0
c-119 0 -130 -2 -140 -20 -10 -18 -8 -20 20 -20 16 0 30 -3 30 -7 0 -5 -25
-102 -54 -218 -53 -208 -53 -210 -82 -215 -17 -3 -30 -12 -32 -23 -3 -16 10
-17 166 -17 146 0 171 2 176 16 9 24 9 24 -29 24 -19 0 -35 5 -35 10 0 6 9 45
21 88 11 42 29 110 39 150 17 67 53 135 85 162 18 15 57 17 62 3 2 -7 -7 -15
-21 -19 -34 -8 -47 -26 -48 -65 -3 -91 138 -92 185 -2 17 34 22 88 11 118 -9
21 -57 45 -91 45 -15 -1 -45 -9 -66 -20z"
          />
          <path
            d="M1884 4236 c10 -8 41 -34 68 -58 28 -24 80 -64 117 -90 59 -42 103
-70 252 -158 86 -51 180 -72 247 -54 49 14 8 80 -88 144 -13 8 -63 45 -111 81
-49 37 -124 84 -167 105 -76 39 -78 39 -207 42 -115 2 -128 1 -111 -12z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
